/*================================================================================
    Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
    Version: 1.0
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.card {
  width: 100%;
  height: 100%;
}

.card-body {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  align-items: center;
}

.card-login .brand-logo {
  width: 136px;
  background-color: rgb(10, 10, 10, 0.65);
  border-radius: 0%;
  border-top-left-radius: 17px 16px;
  border-bottom-left-radius: 17px 16px;
  border-top-right-radius: 17px 16px;
  border-bottom-right-radius: 17px 16px;
  margin-top: 5px;
  margin-bottom: -5px;
}

.card-login .brand-text {
  font-weight: 600;
  display: inline;
  padding-left: 0px;
  vertical-align: middle;
}

.card-login {
  height: auto;
}

.card-body-login {
  display: block;
}

.VictoryContainer svg {
  max-height: 1000px;
}

.subtitle {
  padding: 12px 30px 0 30px;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.card-legend-detailview {
  padding: 50px;
}

/* Fullscreen modal for detailview */
.detail-table-scroll {
  min-height: 400px;
  height: 400px;
  overflow-y: scroll;
}

.spinner-border {
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.leaflet-map-pane-sw {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  z-index: 0;
}

.leaflet-map-pane-color {
  z-index: 0;
}

.basicTableWrapper {
  overflow: auto;
  width: 100%;
  max-height: 650px;
}

.columnPie {
  min-width: 250px;
  max-width: 320px;
  }
@media only screen and (min-width: 1200px){
  .columnPie {
     max-width: 410px;
  }
}

.columnTable {
}

.columnBar {
  min-width: 460px;
  max-width: 1000px;
}
@media only screen and (min-width: 1200px){
  .columnBar {
     min-width:620px;
     max-width: 800px;
  }
}

.col.chartMaximized {
  max-width: none;
  min-width: 750px;
  max-width: 1000px;
}

.col.minimized {
  width: 200px;
  min-width: 200px;
}

.container {
  max-width: none;
}

.temporalPieIndicator {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  width: 30%;
  bottom: 30px;
  margin-left: 36%;
  position: absolute;
}

.temporalPieHLine {
  margin: 0px;
  padding: 0px;
}

.temporalPieRange {
  height: 20px;
  width: 100% - 30px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: transparent;
}

.temporalPieRangeThumb {
  height: 20px;
  width: 30px;
  background-color: #8499b5;
}

.dropdownMenuFilter {
  width: 300px;
}

.newPresetIsPrivate {
  margin-top: 3px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0.1px;
}

input[type="checkbox"] + label {
  margin-right: 10px;
}
label,
input[type="checkbox"] {
  cursor: pointer;
  display: flex;
  margin-right: 10px;
  margin-top: 4px;
}
fieldset {
  border: none;
  margin-left: 10px;
}
.modalColumn {
  float: left;
  width: 50%;
}

.modalRow:after {
  content: "";
  display: table;
  clear: both;
  margin: 5px;
}
.legendPieGeneric {
  overflow: auto;
  height: 100%;
  width: 100%;
  position: relative;
  max-height: 250px;
  text-overflow: ellipsis;
}

.filter-bar {
  margin: 7px 8px 7px 8px;
}
.filter-label {
  font-weight: 600;
  margin-right: 12px;
  color: #464854;
}
.filter-values {
  color: #464854;
}
.legend {
  color: black;
}
.legendStrategie {
  padding-left: 10px;
}
.elementLegendStrategie {
  display: inline-block;
  float: left;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.filter-apply,
.filter-apply:hover {
  color: #2162a0;
  padding: 0;
  background-color: transparent;
  border: 0;
  float: right;
  margin-right: 12px;
}
.filter-apply.filterpanel {
  padding-left: 10px;
}
.maximizeChart {
  color: #2162a0;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 5px;
}
.csvExportTable {
  color: #2162a0;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 5px;
}
.filter-dropdown,
.filter-dropdown:hover {
  padding: 0;
  background-color: transparent;
  border: 0;
  float: right;
}
.filter-dropdown-button,
.filter-dropdown-button:hover {
  color: #cc890d;
  padding: 0;
  background-color: transparent;
  border: 0;
}
.filter-dropdown-remove,
.filter-dropdown-remove:hover {
  padding: 0;
  float: right;
}
.dropdown-chart-menu-selected {
  font-weight: bold;
}
.check-chart-menu {
  margin-left: 10px;
}
.datenMain {
  position: absolute;
  left: 0;
  right: 0;
  top: 30px;
  bottom: 0;
  overflow: auto;
}
.chartCard {
  margin: 0px;
  overflow: hidden;
}
.ButtonChartTitle {
  background-color: #8499b5;
  position: relative;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-top: 3px;
  margin-bottom: -37px;
  color: white;
  position: relative;
  border: none;
}
.chartDiv {
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
}
.chart-title {
  font-size: 14px;
  margin-bottom: 0px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.chartDiv.minimized > .chart-title > .ButtonChartTitle {
  padding-bottom: 3px;
  margin-bottom: 0px;
}
.chartDiv.minimized > .chartCard {
  display: none;
}
.mapDetail {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  top: 0;
  height: 50%;
  width: 100%;
}

.containerDiagrams {
  padding-left: 20px;
  padding-right: 20px;
}
.containerDiagrams.inner {
  padding-top: 10px;
  padding-left: 25px;
}
.dropdown-menuUser {
  cursor: pointer;
  position: absolute !important;
  left: 0em;
  top: 2.8em;
}
.dropdown-menuNav {
  left: auto;
  right: 0px;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 1px solid #a8b2af;
}
.leaflet-control-layers-expanded {
  padding: 12px 20px 12px 12px;
  color: #5e6060;
  min-width: 190px;
}
.leaflet-control select {
  border: 1px solid #a8b2af;
  height: 25px;
  background: #fff;
  color: black;
  min-width: 150px;
}
.leaflet-control button {
  border: 1px solid #a8b2af;
  height: 27px;
  background: #fff;
  color: black;
  min-width: 27px;
  border-radius: 5px;
}
.leaflet-control-container .leaflet-top.leaflet-left {
  top: 1px;
  margin-left: 20px;
}
.leaflet-control-geosearch form input {
  height: 25px;
}
.leaflet-control-geosearch a.reset {
  height: 25px;
  line-height: 25px;
}
.header-navbar {
  min-height: 37px;
}
.header-navbar .navbar-wrapper {
  width: 100%;
  height: 37px;
}
.navbarDashboards {
  min-height: 10px;
  height: 35px;
}
html body .app-content.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.mainSidebar {
  position: absolute;
  width: 0%;
  top: 70px;
  left: 0;
  bottom: 0;
  overflow: visible;
  background-color: #dce0df;
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.mainSidebarContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.mapDetail,
.mainSidebarContainer {
  scrollbar-color: light;
  scrollbar-width: thin;
}
.open .mainSidebar {
}
.closed .mainSidebar > * {
  display: none;
}
.closed .mainSidebar > .sidebarToggle {
  display: block;
}
.sidebarToggle {
  background-color: #dce0df;
  width: 17px;
  height: 48px;
  position: absolute;
  top: 56px;
  right: -17px;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.sidebarToggle span {
  font-size: 12px;
  margin-top: 14px;
}
.containerInOutCaret {
  position: relative;
}
.inOutCarat {
  background-color: #dce0df;
  width: 18px;
  height: 22px;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0 0px 4px 0;
  display: block;
  position: absolute;
  cursor: pointer;
  z-index: 999;
}
.inOutCarat span {
  font-size: 16px;
  margin-top: 1px;
}
.zoomOutCarat {
  background-color: #dce0df;
  width: 18px;
  height: 22px;
  top: -22px;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0 4px 0px 0;
  display: block;
  position: absolute;
  cursor: pointer;
  z-index: 999;
}
.zoomOutCarat span {
  font-size: 16px;
  margin-top: 1px;
}
.mainColumn {
  position: absolute;
  top: 72px;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
}
.rowObjectview {
  margin: 0;
}
.mainColumn.sidebarOpen {
}
.mainColumn.sidebarClosed {
  width: 100%;
}
.mapView {
  position: relative;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.mapViewFull {
  position: absolute;
  height: 100%;
  width: 100%;
}
.mapWrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.viewHeader {
  width: 100%;
  padding-right: 20px;
  padding-top: 0px;
  background-color: #ffffff;
}
.user-nav {
  z-index: 30000;
}
.section-nav {
  z-index: 10000;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-autor {
  padding-right: 4px;
  padding-left: 4px;
}
.container {
  padding-left: 19px;
  padding-right: 19px;
}
.badge-information {
  background-color: #2162a0;
  padding: 3px 2px;
}
.badge-information span {
  bottom: 0 !important;
}
html body .la.la-file {
  font-size: 15px;
}
.bullit {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  display: inline-block;
}
.bullit.success {
  background: #28d094;
}
.bullit.red {
  background: #f44336;
}

body .btn-outline-primary {
  color: #376790;
  border-color: #376790;
}
body .btn-outline-primary:hover {
  border-color: #376790;
  background-color: #376790;
}
h1.dashboard {
  font-weight: bold;
  margin: 16px 0 40px;
  line-height: 43px;
}
.card .project-name.card-title {
  text-align: center;
  color: white;
  font-size: 22;
  font-weight: bold;
  border: 1px solid #274261;
  padding: 0px;
  border-radius: 25px;
  background-color: #274261;
}
.datenMain .table th,
.datenMain .table td {
  border: 0;
  padding: 0.25em 1em;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
}
.project-sub {
  font-size: 16px;
  font-weight: bold;
}
.datenMain .card {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1053px) {
  .datenMain .card {
    width: 70%;
  }
}
.upload {
  padding: 15px;
  text-align: right;
}
body .upload .btn-outline-primary {
  color: #ffffff;
  border-color: #2162a0;
  background-color: #2162a0;
}
body .upload .btn-outline-primary:hover {
  color: #ffffff;
  border-color: #2162a0;
}
body .upload .btn-outline-primary.btn-blue-right {
  border-right: solid 2px #274261 !important;
}
.btn-info.btn-add,
.btn-info.btn-add:hover {
  background-color: #d19d3a !important;
  border-color: #d19d3a !important;
}
.upload-sub {
  font-size: 13px;
  font-weight: bold;
  line-height: 30px;
}

.loader {
  position: relative;
  left: 10px;
  top: 8px;
}

.navbar-dark .navbar-nav li {
  line-height: 0;
}

.warningNulls {
  color: #9c0000;
}

.rowObjectview {
}

.rowObjectview.active {
  background-color: #b0cbf5;
}

.pageChooser {
  margin: 0;
  display: inline-block;
}

.wrapperCentering {
  padding-top: 20px;
  text-align: center;
}

.colTableObjectviewGrunddaten {
}

.divTableObjectviewGrunddaten {
  min-width: 60vw;
  max-width: 100vw;
  padding-left: 20px;
  padding-right: 15px;
  padding-top: 6px;
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.TableObjectviewGrunddaten {
  width: 100%;
  overflow: scroll;
}

.TableObjectviewGrunddaten input {
  height: 10px;
}

.TableObjectviewGrunddaten th,
.TableObjectviewGrunddatenInspections th {
  padding: 0px 0.4rem 5px 0.4rem;
}

.TableObjectviewGrunddaten td,
.TableObjectviewGrunddatenInspections td {
  padding: 0px 0.4rem 5px 0.4rem;
}

.table thead th {
  border-top: none;
}

.colTableObjectviewGrunddaten .vline {
  border-left: 2px solid #d6d6d6;
  height: 100vh;
}

.colTableObjectviewGrunddatenInspections {
  padding: 0;
}

.divTableObjectviewGrunddatenInspections {
  min-width: 20vw;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 28px;
}

.TableObjectviewGrunddatenInspections {
  width: 100%;
  min-width: 200px;
}

.wrapperObjectviewObject {
  padding: 20px;
}

.buttonChangelog {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: transparent;
  border: none;
  color: #a0a0a0;
  font-size: 100px;
}

.buttonChangelog:hover {
  background: none;
  color: #a0a0a0;
}
.buttonChangelog:active:focus {
  background: none !important;
  color: #a0a0a0 !important;
}
.buttonChangelog.la-info-circle {
  font-size: 50px;
  position: fixed;
  bottom: 50px;
}
.header-navbar.navbar-brand-center .navbar-header {
  width: 400px;
}
.dropdown-itemNav {
  position: relative;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}
.multi-range {
  width: 89%;
  margin-left: 44px;
}
.MuiSlider-root.yearSlider {
  width: 60%;
  margin-left: 44px;
  margin-right: 15px;
  top: 10px;
  display: inline-flex;
  float: left;
  padding: 0;
}

.sliderSpan {
  margin-left: 3px;
}
@media (max-width: 1050px) {
  .sliderSpan {
    margin-left: 42px;
  }
}
.yearNumber {
  margin-left: 3px;
}
@media (max-width: 1200px) {
  .yearNumber {
    margin-left: 334px;
  }
}

.dropdownObjectviewNeighbor {
  float: right;
}

.title-sidebar {
  display: contents;
}

.raj {
  max-height: calc(100vh - 450px) !important;
  overflow-y: scroll;
}
